import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";

import { SharedService } from "../../services/shared.service";
import { EventsService } from "./events.service";
import { UsersService } from "../account-management/users/users.service";

import { Tag, UserPermissions } from "../../models/shared";
import { ZmEvent, EventsFilter } from "./event";
import { TitleService } from "../../services/title.service";
import { ModalService } from "../../components/shared/modals/modal.service";
import {
    EditRuleDialogComponent,
    EditRuleDialogData
} from "src/app/pages/configuration/events-management/edit-rule-dialog/edit-rule-dialog.component";
import { EventObjectType, TypeFilterLabel } from "src/app/components/events-filter-form/events-filter-form.component";

interface State {
    page: number;
    pageSize: number;
}

@Component({
    selector: "app-events",
    templateUrl: "./events.component.html",
    providers: [MatDialog]
})
export class EventsComponent implements OnInit, OnDestroy {
    loading = true;
    events: ZmEvent[] = [];
    total: number;
    userPermissions: UserPermissions;

    resourceTags: Tag[];
    lastFilters: EventsFilter;

    autoPageSize: boolean;
    selectedPageSize: string | number;

    tableExpanded: boolean;

    eventsFilter = [
        { text: "Error", color: "danger", key: "error", enabled: true },
        { text: "Warning", color: "warning", key: "warning", enabled: true },
        { text: "Info", color: "info", key: "info", enabled: true },
        { text: "Ok", color: "success", key: "success", enabled: true }
    ];

    // 11 Types
    objectTypes: EventObjectType[] = [
        { name: "ANY", type: undefined },
        { name: "FEEDERS", type: ["feeder"] },
        { name: "BROADCASTERS", type: ["broadcaster"] },
        { name: "BROADCASTER_CLUSTERS", type: ["broadcaster_cluster"] },
        { name: "RECEIVERS", type: ["receiver"] },
        { name: "REMOTE_ACCESS", type: ["remote_access"] },
        { name: "SOURCES", type: ["source", "mediaconnect_sources"] },
        {
            name: "CHANNELS",
            type: [
                "adaptive_channel",
                "delivery_channel",
                "failover_channel",
                "mediaconnect_flows",
                "mediaconnect",
                "medialive_channels"
            ]
        },
        {
            name: "TARGETS",
            type: [
                "publishing_target",
                "zixi_pull",
                "zixi_push",
                "rtmp_push",
                "udp_rtp",
                "rist",
                "srt_targets",
                "ndi_targets",
                "medialive_http_targets",
                "mediaconnect_jpegxs_targets",
                "mediaconnect_cdi_targets"
            ]
        },
        { name: "LIVE_EVENTS", type: ["live_events"] },
        { name: "AUTOMATION", type: ["task_sets"] },
        { name: "USER_EVENTS", type: ["user"] },
        { name: "ACCOUNT_MANAGEMENT", type: ["account_management"] },
        { name: "MULTI_VIEWERS", type: ["multi_viewers"] }
    ];

    columns: {
        date_time: boolean;
        message: boolean;
        rule: boolean;
    };

    private eventsSubscription: Subscription;
    private userPermissionsSubscription: Subscription;

    private eventsBS$ = new BehaviorSubject<ZmEvent[]>([]);
    private totalBS$ = new BehaviorSubject<number>(0);

    private state: State = {
        page: 1,
        pageSize: 20
    };

    private offset = "";
    typeFilterLabel: TypeFilterLabel = "TYPES";

    @ViewChild("listPanel", { static: true }) listPanel: ElementRef;
    @ViewChild("listBottom", { static: true }) listBottom: ElementRef;

    @HostListener("window:resize", [])
    private onResize() {
        if (this.autoPageSize) {
            this.calcRows();
            this.prepTableData();
        }
    }

    constructor(
        public sharedService: SharedService,
        private es: EventsService,
        private userService: UsersService,
        private titleService: TitleService,
        private modalService: ModalService,
        private dialog: MatDialog
    ) {
        // Set Title
        this.titleService.setTitle("EVENTS", "");
    }

    ngOnInit() {
        this.userPermissionsSubscription = this.userService.userPermissions.subscribe(obj => {
            this.userPermissions = obj;
        });

        this.sharedService
            .getResourceTagsByType(null, true)
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
            });

        const columns = {
            date_time: true,
            message: true,
            rule: true
        };

        const localColumns = JSON.parse(localStorage.getItem("events.visibleColumns"));

        if (localColumns) {
            Object.keys(localColumns).forEach(key => {
                if (key in columns) {
                    columns[key] = localColumns[key];
                }
            });
        }

        this.columns = columns;

        // Get pageSize
        if (!localStorage.getItem("pageSize") || localStorage.getItem("pageSize") === "auto") {
            this.selectedPageSize = "auto";
            this.autoPageSize = true;
            this.calcRows();
        } else {
            this.pageSize = +localStorage.getItem("pageSize");
            this.selectedPageSize = +localStorage.getItem("pageSize");
            this.autoPageSize = false;
        }

        this.eventsSubscription = this.es.events.subscribe(events => {
            this.events = events;
            if (this.events) {
                this.prepTableData();
                this.loading = false;
            }
        });

        // Setup
        this.events = [];
        this.loading = true;

        // getEvents
        this.es.getEvents({
            offset: this.offset,
            pageSize: this.pageSize,
            ...this.lastFilters
        });
    }

    ngOnDestroy() {
        this.userPermissionsSubscription.unsubscribe();
        this.eventsSubscription.unsubscribe();
        this.es.clearEvents();
    }

    toggleColumn(column: { key: string; value: boolean }) {
        this.columns[column.key] = !column.value;
        // Convert object to string;
        const s = JSON.stringify(this.columns);
        // Set visible columns in local storage
        localStorage.setItem("events.visibleColumns", s);
    }

    get events$() {
        return this.eventsBS$.asObservable();
    }
    get total$() {
        return this.totalBS$.asObservable();
    }
    get page() {
        return this.state.page;
    }
    set page(page: number) {
        this._set({ page });
    }
    get pageSize() {
        return this.state.pageSize;
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }

    private _set(patch: Partial<State>) {
        Object.assign(this.state, patch);
        this.prepTableData();
    }

    // Prep Table Data
    private prepTableData() {
        const { pageSize, page } = this.state;

        this.offset = this.events?.length > 0 ? this.events[this.events.length - 1].id : "";

        // 1. sort
        let events = this.events;

        // 2. size
        let total = events.length;
        this.total = events.length;
        if (events.length % pageSize === 0) {
            total = events.length + 1;
            this.total = events.length + 1;
        }

        // 3. paginate
        events = events.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

        this.eventsBS$.next(events);
        this.totalBS$.next(total);
    }

    calcRows() {
        this.pageSize = this.sharedService.calcRows(
            this.listPanel.nativeElement.offsetHeight,
            0,
            this.listBottom.nativeElement.offsetHeight
        );
    }

    pageSizeChanged(pageSize) {
        if (pageSize === "auto") {
            localStorage.setItem("pageSize", "auto");
            this.calcRows();
            this.prepTableData();
            this.autoPageSize = true;
        } else {
            localStorage.setItem("pageSize", pageSize.toString());
            this.pageSize = pageSize;
            this.prepTableData();
            this.autoPageSize = false;
        }
        this.reloadEvents();
    }

    // Reload Events
    async reloadEvents() {
        this.loading = true;
        this.page = 1;
        await this.es.refreshEvents({
            offset: "",
            pageSize: this.pageSize,
            ...this.lastFilters
        });
        this.loading = false;
    }

    async applyFilters(filters) {
        this.loading = true;
        this.page = 1;
        await this.es.refreshEvents({
            offset: "",
            pageSize: this.pageSize,
            ...filters
        });
        this.lastFilters = filters;
        this.loading = false;
    }

    // Reset Events
    async resetEvents() {
        this.loading = true;
        this.page = 1;
        await this.es.refreshEvents({ offset: "", pageSize: this.pageSize });
        this.loading = false;
    }

    // Page Change
    async pageChange(val: number) {
        if (val * this.pageSize < this.events.length) return;
        if (this.events.length === this.total) return;
        this.loading = true;
        await this.es.loadMoreEvents({
            offset: this.offset,
            pageSize: this.pageSize,
            ...this.lastFilters
        });
        this.loading = false;
    }

    // Manage Event
    async manageEvent(event: ZmEvent) {
        this.dialog.open<EditRuleDialogComponent, EditRuleDialogData>(EditRuleDialogComponent, {
            data: event,
            width: "600px"
        });
    }

    async createIncident(event: ZmEvent) {
        const object = {
            id: event.object_id,
            name: event.object_name,
            type: event.object_type
        };

        const finalObject = { [event.object_type]: object };

        await this.modalService.addIncident(
            finalObject,
            moment(event.event_date).subtract(15, "minutes"),
            moment(event.event_date).add(15, "minutes"),
            event
        );
    }

    getObjectType(type: string): string {
        switch (type) {
            case "groups":
                return "user_group";
            default:
                return type;
        }
    }
}
